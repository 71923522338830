
import React from 'react'

const Invoice = (props) => {
  console.log(props);
  return (
    <div>
  
    </div>
  )
}

export default Invoice;